<template>
    <div>
        <!-- TODO: we need an error modal. waiting on design/business
			<DialogModal
            ariaLabel="Payment page issue."
            :showCloseButton="false"
            :isOpen="hasConsumerLeadError"
            :disableEscape="true"
        >
            <h4 slot="header">Consumer Lead Error</h4>
            <p>Oh dang!</p>
            <button slot="footer">Reload Page</button>
        </DialogModal> -->

        <Checkout
            :hasCreditCardCheckout="hasCCCheckout"
            :query="query"
            @taxesDone="trackCheckoutStarted"
        />
    </div>
</template>

<script>
import Checkout from '@/components/Checkout.vue';
import { isEmpty } from 'underscore';
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex';
import { RudderstackCheckoutMixin } from '@/rudderstack/track/mixins/checkout';
import { RudderstackChatMixin } from '@/rudderstack/track/mixins/chat';
import DialogModal from '@/components/DialogModal.vue';

const axios = require('axios');

export default {
	components: {
		Checkout,
		DialogModal
	},

	mixins: [RudderstackCheckoutMixin, RudderstackChatMixin],

	props: {
		query: {
			required: true,
			type: Object
		}
	},

	data() {
		return {
			hasConsumerLeadError: false
		};
	},

	watch: {
		'$store.state.consumerInfo.userLanguage': function() {
			// true fed to fetchContent to trigger re-fetching of content if userLanguage is changed
			this.fetchContent(true);
		}
	},

	computed: {
		...mapGetters('cart/order', ['isMobileInstallation']),
		...mapState('dealer', {	selectedDealer: 'selected' }),
		...mapState('cart', ['tiresInCart']),
		...mapState('cart/order', ['details']),
		...mapState('consumerInfo', ['userLanguage']),
		...mapState('initial', ['title']),
		...mapState('search', ['dealership_id']),

		hasCCCheckout() {
			return this.$store.state.initial.hasCreditCardCheckout;
		},
		newPageTitle() {
			// eslint-disable-next-line prefer-template
			const page = this.$t('message.checkoutPage') + ' | ' + this.title;

			return page;
		}
	},

	methods: {
		...mapActions('dealer', { selectDealer: 'select' }),
		...mapActions('cart', ['fetchCart']),
		...mapActions('products', ['getInventory']),
		...mapActions('appointment', ['getMobileInstallZip']),
		...mapActions('serviceOfferings', ['addMobileInstallFee']),
		...mapActions('content/payment', ['fetchContent']),
		...mapMutations('products', ['checkStaggered']),
		...mapMutations('cart/order', ['setOrder']),
		...mapActions('cart/order', ['consumerLeadInitial']),
		...mapActions('authvia', ['setupAuthviaTokenValues']),
		...mapMutations('authvia', ['setAuthviaToken', 'setAuthviaTokenValue', 'setAuthviaMerchantClientID']),

		async handlePageLoadWithNoDealerSelected() {
			const qs = this.query;

			// This block will execute on a page refresh
			if (isEmpty(this.selectedDealer)) {
				// Action - Sets store.dealer.selected
				await this.selectDealer({
					uid: qs.uid,
					make: qs.make
				});
				// Mutation - Sets store.products.isStaggered
				await this.checkStaggered(qs.sku);
				// Get cart from session
				await this.fetchCart({
					...qs,
					programList: this.selectedDealer.program.code,
					programCode: this.selectedDealer.program.code,
					dealerCode: this.selectedDealer.dealerCode,
					allowRoadHazard: this.selectedDealer.allowRoadHazard,
					dealershipid: this.selectedDealer.dealershipid
				});
				await this.getInventory({
					products: this.tiresInCart.products,
					qty: !Array.isArray(qs.qty) ? qs.qty.split(',') : qs.qty,
					dealer: this.selectedDealer
				});

				// Action - Sets store.schedule.appointment
				if (this.isMobileInstallation) {
					// Action - Sets mobileInstallInfo.zip in cart/order/details
					this.getMobileInstallZip();
					this.addMobileInstallFee();
				}
			}
		},
		async getInitialConsumerLeadID() {
			// Generate consumerLeadID with order details (this.details)
			try {
				let leadInitialError = await this.consumerLeadInitial(this.details);

				if (leadInitialError) {
					this.hasConsumerLeadError = true;
					console.error('Error initializing consumer lead: ', leadInitialError);
				}
			} catch (err) {
				this.hasConsumerLeadError = true;
				console.error('Error fetching consumer lead: ', err);
			}
		},
		setOrderDetails() {
			const qs = this.query;

			// Mutation- Adds values to $store.cart.order.details

			this.setOrder({
				uid: qs.uid,
				vehicle: {
					year: qs.year,
					make: qs.make,
					model: qs.model,
					trim: qs.trim,
					option: qs.option,
					containsStaggeredSet: qs.sku.split(',').length > 1
				},
				leadSearchType: qs.isbysize ? 'searchByTireSize' : 'searchByVehicle'
			});
		},
		async setupPaymentPage() {
			// 1. no dealer selected (on page refresh)
			await this.handlePageLoadWithNoDealerSelected();

			// 2. set order details
			await this.setOrderDetails();

			// 3. get consumerLeadID
			if (this.selectedDealer.authviaEnabled) {
				// 3a. get lead ID
				// TODO: Check if we already have a consumer lead id
				await this.getInitialConsumerLeadID();

				// 3b. get Authvia Token
				await this.setupAuthvia();
			}
		},
		// eslint-disable-next-line complexity
		async setupAuthvia() {
			// if has cc processing, get Authvia token
			const authviaToken = sessionStorage.getItem('authviaToken');
			const authviaTokenValue = sessionStorage.getItem('authviaTokenValue');
			const merchantClientID = sessionStorage.getItem('authviaMerchantClientID');

			if (authviaTokenValue && merchantClientID) {
				this.setAuthviaToken(JSON.parse(authviaToken));
				this.setAuthviaTokenValue(authviaTokenValue);
				this.setAuthviaMerchantClientID(merchantClientID);
			}

			if (!authviaTokenValue || !merchantClientID) {
				const { data } = await axios.get('/authvia/customertoken', {
					params: {
						dealerID: this.selectedDealer.dealershipid,
						consumerLeadID: this.details.leadID
					}
				});

				this.setupAuthviaTokenValues(data);
			}
		}
	},

	mounted() {
		document.title = this.newPageTitle;
	},

	async created() { // eslint-disable-line complexity
		if (sessionStorage.getItem('orderNo') !== '') {
			sessionStorage.removeItem('orderNo');
		}

		// Async - Get CMS Content
		this.fetchContent();

		await this.setupPaymentPage();

		await this.$rudderstack.page(this.$route.meta.category, this.$route.meta.name, {
			...this.$route.query,
			'dealership_id': this.dealership_id
		});

	},
	// If the browser doesn't have the needed query strings boot back to index
	beforeRouteEnter(to, from, next) {
		next( vm => {
			if (!vm.$route.query.make && !vm.$route.query.sku && !vm.$route.query.qty) {
				return vm.$router.push('/');
			}

			return true;
		});
	}
};
</script>
