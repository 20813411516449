<template>
    <!-- Param action, method, and target -->
    <form
        :action="getAction"
        :method="getMethod"
        class="row billing"
        @submit.prevent="validateBeforeSubmit"
    >

        <!-- BILLING INFORMATION -->
        <fieldset class="col-12 col-md-11">
            <h5 class="billing__header">
                {{ $t("message.yourInformation") }}
            </h5>
            <small class="billing__description">
                {{ $t("message.yourPrivacyIsImportant") }}
                {{ $t("message.allFieldsRequired") }}
            </small>

            <div class="row">
                <div class="form-group col-xl-6 col-lg-8 col-md-10 col-sm-12" role="group" :aria-label="$t('message.billingLabel')">
                    {{ $t("message.billingLabel") }}
                    <ul class="input__list">

                        <!-- First Name -->
                        <li class="input billing--input">
                            <label for="bill_to_forename" class="input__label input">
                                {{ $t("message.fName") }}
                                <input
                                    name="bill_to_forename"
                                    id="bill_to_forename"
                                    autocomplete="given-name"
                                    type="text"
                                    maxlength="100"
                                    v-model="billing.firstName"
                                    data-vv-name="firstName"
                                    @blur="trackCheckoutStepModified({ checkout_step: 'firstName' })"
                                    v-validate="{
                                        required: true,
                                        regex: /^[a-zA-Z]+[a-zA-Z .,'-]*$/,
                                    }"
                                    :class="[
                                        'input__field',
                                        { 'input__field--error': errors.has('firstName') },
                                    ]"
                                    data-cy="billingFirstName"
                                />
                            </label>
                            <small
                                v-if="errors.has('firstName')"
                                role="alert"
                                class="input__error-reason"
                            >
                                {{ errors.first("firstName") }}
                            </small>
                        </li>
                        <!-- Last Name -->
                        <li class="input billing--input">
                            <label for="bill_to_surname" class="input__label">
                                {{ $t("message.lName") }}
                                <input
                                    name="bill_to_surname"
                                    id="bill_to_surname"
                                    autocomplete="family-name"
                                    type="text"
                                    maxlength="100"
                                    v-model="billing.lastName"
                                    @blur="trackCheckoutStepModified({ checkout_step: 'lastName' })"
                                    data-vv-name="lastName"
                                    v-validate="{
                                        required: true,
                                        regex: /^[a-zA-Z]+[a-zA-Z .,'-]*$/,
                                    }"
                                    :class="[
                                        'input__field',
                                        { 'input__field--error': errors.has('lastName') },
                                    ]"
                                    data-cy="billingLastName"
                                />
                            </label>

                            <small
                                v-if="errors.has('lastName')"
                                role="alert"
                                class="input__error-reason"
                            >
                                {{ errors.first("lastName") }}
                            </small>
                        </li>

                        <!-- Address/City/State/Zip -->
                        <li class="input billing--input">
                            <label for="bill_to_address_line1" class="input__label">
                                {{ $t("message.address") }}
                                <input
                                    name="bill_to_address_line1"
                                    id="bill_to_address_line1"
                                    autocomplete="street-address"
                                    type="text"
                                    maxlength="100"
                                    v-model="billing.address.line1"
                                    @blur="trackCheckoutStepModified({ checkout_step: 'address' })"
                                    data-vv-name="address"
                                    v-validate="{
                                        required: true,
                                        min: 3,
                                        regex: /^[a-zA-Z0-9 ,'\-#.]*$/,
                                    }"
                                    :class="[
                                        'input__field',
                                        {
                                            'input__field--error': errors.has(
                                                'address'
                                            ),
                                        },
                                    ]"
                                    data-cy="billingAddress1"
                                />
                            </label>
                            <small
                                v-if="errors.has('address')"
                                role="alert"
                                class="input__error-reason"
                            >
                                {{ errors.first("address") }}
                            </small>
                        </li>
                        <li for="bill_to_address_city" class="input billing--input">
                            <label class="input__label">
                                {{ $t("message.city") }}
                                <input
                                    name="bill_to_address_city"
                                    id="bill_to_address_city"
                                    type="text"
                                    autocomplete="address-level2"
                                    maxlength="100"
                                    v-model="billing.address.city"
                                    @blur="trackCheckoutStepModified({ checkout_step: 'city' })"
                                    data-vv-name="city"
                                    v-validate="{
                                        required: true,
                                        regex: /^[a-zA-Z]+(?:[ -][a-zA-Z]+)*$/,
                                    }"
                                    :class="[
                                        'input__field',
                                        {
                                            'input__field--error': errors.has(
                                                'city'
                                            ),
                                        },
                                    ]"
                                    data-cy="billingCity"
                                />
                            </label>
                            <small
                                v-if="errors.has('city')"
                                role="alert"
                                class="input__error-reason"
                            >
                                {{ errors.first("city") }}
                            </small>
                        </li>
                        <li class="input billing--input-small">
                            <label for="bill_to_address_state" class="input__label">
                                {{ $t("message.state") }}
                                <select
                                    name="bill_to_address_state"
                                    id="bill_to_address_state"
                                    autocomplete="street-address"
                                    v-model="billing.address.state"
                                    data-vv-name="state"
                                    @blur="trackCheckoutStepModified({ checkout_step: 'state' })"
                                    v-validate="'required'"
                                    :class="[
                                        'input__field',
                                        'input__state',
                                        {
                                            'input__field--error': errors.has(
                                                'state'
                                            ),
                                        },
                                    ]"
                                    data-cy="billingState"
                                >
                                    <option
                                        v-for="state in statesList"
                                        :value="state"
                                        :key="state.id"
                                    >
                                        {{ state }}
                                    </option>
                                </select>
                            </label>
                            <small
                                v-if="errors.has('state')"
                                role="alert"
                                class="input__error-reason"
                            >
                                {{ $t("message.stateError") }}
                            </small>
                        </li>
                        <li class="input billing--input-small">
                            <label for="bill_to_address_postal_code" class="input__label">
                                {{ $t("message.zipCode") }}
                                <input
                                    name="bill_to_address_postal_code"
                                    id="bill_to_address_postal_code"
                                    autocomplete="postal-code"
                                    type="text"
                                    :minlength="zipMinLength"
                                    :maxlength="zipMaxLength"
                                    v-model="billing.address.zipCode"
                                    :readonly="useBillingForMobileInstall"
                                    data-vv-name="zipCode"
                                    @blur="trackCheckoutStepModified({ checkout_step: 'zipCode' })"
                                    v-validate="{
                                        required: true,
                                        regex:  zipRegex
                                    }"
                                    :class="[
                                        'input__field',
                                        {
                                            'input__field--error': errors.has(
                                                'zipCode'
                                            ),
                                        },
                                    ]"
                                    @keyup="uppercaseZip"
                                    data-cy="billingZipCode"
                                />
                            </label>
                            <small
                                v-if="errors.has('zipCode')"
                                role="alert"
                                class="input__error-reason"
                            >
                                {{ errors.first("zipCode") }}
                            </small>
                        </li>

                        <!-- Use Billing for Mobile Install-->
                        <label
                            class="input__label mobile__label"
                            v-if="isMobileInstallation"
                            for="useBillingInfoForMobileInstall"
                            :aria-label="$t('message.useBillingInfoForMobileInstall')"
                        >
                            <input autocomplete
                                   type="checkbox"
                                   name="useBillingInfoForMobileInstall"
                                   id="useBillingInfoForMobileInstall"
                                   v-model="useBillingForMobileInstall"
                            />
                            {{ $t("message.useBillingInfoForMobileInstall") }}
                        </label>

                        <!-- Phone/Email -->
                        <li class="input billing--input-medium">
                            <label for="bill_to_phone" class="input__label">
                                {{ $t("message.phone") }}
                                <input
                                    name="bill_to_phone"
                                    id="bill_to_phone"
                                    autocomplete="tel"
                                    type="tel"
                                    placeholder="(___)___-____"
                                    v-mask="'(###)###-####'"
                                    v-model="billing.phone"
                                    data-vv-name="phoneNumber"
                                    @blur="trackCheckoutStepModified({ checkout_step: 'phoneNumber' })"
                                    v-validate="{
                                        required: true,
                                        regex: /^\(\d{3}\)\d{3}-\d{4}$/,
                                    }"
                                    :class="[
                                        'input__field',
                                        { 'input__field--error': errors.has('phoneNumber') },
                                    ]"
                                    data-cy="billingPhone"
                                />
                            </label>
                            <small
                                v-if="errors.has('phoneNumber')"
                                role="alert"
                                class="input__error-reason"
                            >
                                {{ errors.first("phoneNumber") }}
                            </small>
                        </li>

                        <li class="input billing--input">
                            <label for="bill_to_email" class="input__label">
                                {{ $t("message.email") }}
                                <input
                                    name="bill_to_email"
                                    id="bill_to_email"
                                    autocomplete="email"
                                    type="email"
                                    maxlength="100"
                                    v-model="billing.email"
                                    data-vv-name="emailAddress"
                                    @blur="trackCheckoutStepModified({ checkout_step: 'emailAddress' })"
                                    v-validate="'required|email'"
                                    :class="[
                                        'input__field',
                                        { 'input__field--error': errors.has('emailAddress') },
                                    ]"
                                    data-cy="billingEmail"
                                />
                            </label>
                            <small
                                v-if="errors.has('emailAddress')"
                                role="alert"
                                class="input__error-reason"
                            >
                                {{ errors.first("emailAddress") }}
                            </small>
                        </li>
                    </ul>
                </div>
                <mobile-install-form
                    :checkoutFormSubmitted="submitted"
                    :useBillingForMobileInstall="useBillingForMobileInstall"
                    v-if="!useBillingForMobileInstall && isMobileInstallation"
                />
            </div>

            <!-- Authvia Widget -->
            <div v-if="selected.authviaEnabled" class="row">

                <!-- AuthVia Credit Card Widget -->
                <div class="col-xl-6 col-lg-8 col-md-10 col-sm-12">
                    <!-- Setup Messaging -->
                    <p v-if="!isAuthviaReady && !showSuccessfulTransactionMessage">
                        Setting up Authvia...
                    </p>

                    <!-- Authvia Secure Checkout Disclaimers -->
                    <div>
                        <h5 class="billing__header">
                            {{ $t("message.creditCard") }}
                        </h5>
                        <small class="billing__description">
                            {{ $t("message.ccDisclaimerMessage") }}
                        </small>

                    </div>
                    <div class="info-panel-icon-left">
                        <img src="/dist/assets/svgs/shield.svg" alt="Security Shield Icon" class="info-panel-icon-left__icon">
                        <div class="secure-cc-checkout__content">
                            <h1 class="info-panel-icon-left__title">{{ $t("message.secureCheckoutTitle") }}</h1>
                            <p class="info-panel-icon-left__subtitle">{{ $t("message.secureCheckoutMessage") }}</p>
                        </div>
                    </div>

                    <!-- Widget -->
                    <AuthviaCreditCard
                        v-if="isAuthviaReady && !showSuccessfulTransactionMessage"
                        @paymentMethodSuccess="handlePaymentMethodSuccess"
                    />

                    <!-- Show Successfull Transaction Message -->
                    <AlertBox
                        v-show="showSuccessfulTransactionMessage"
                        :showSuccessfulTransactionMessage="showSuccessfulTransactionMessage">
                    </AlertBox>
                </div>
            </div>
        </fieldset>

        <div
            v-if="financingProductAllowed"
            class="billing__financing-check"
        >
            <input
                type="checkbox"
                v-model="hasFinancing"
                data-cy="billingFinance"
                name="financingInterest"
                id="financingInterest"
            />
            <label for="financingInterest" v-html="this.financingDisclaimer.financingCheckbox"></label>
            <img v-if="!isCreditCard" :src="partnerLogoURL" :alt="partnerLogoName" />
        </div>


        <div
            class="col-12 col-md-11 billing__button-wrapper"
        >
            <stateful-btn
                :isLoading="isLoading && !errorDuringSubmission"
                :disabled="isLoading || (!taxesDone && disableSubmit)"
                :status="status"
                @statefulButtonClicked="handleStatefulButtonClick"
                data-cy="orderSubmitButton"
            >
                <span>{{ $t("message.placeOrder") }}</span>
            </stateful-btn>
        </div>

        <!--
            "Fill Form" button that only renders in lower environments.
            If you see this button, you CAN place test orders.
        -->
        <div v-if="isTestEnv" class="col-12">
            <button
                @click="fillForm"
                class="button button--primary"
                type="button"
            >
                Fill Form
            </button>
            <br>
        </div>

    </form>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { formErrors } from '@/utils/setDataLayer';
import states from '@/store/data/states.json';
import provinces from '@/store/data/provinces.json';
import MobileInstallForm from '@/components/MobileInstallForm.vue';
import { RudderstackCheckoutMixin } from '@/rudderstack/track/mixins/checkout';
import AuthviaCreditCard from '@/components/AuthviaCreditCard.vue';
import AlertBox from '@/components/AlertBox.vue';
import statefulBtn from '@/components/StatefulButton.vue';

export default {
	name: 'CheckoutForm',

	props: {
		taxesDone: {
			type: Boolean
		},
		financingDisclaimer: {
			type: Object
		},
		financePartner: {
			type: Object
		},
		errorDuringSubmission: {
			type: Boolean,
			default: false
		},
		newAppointmentChosen: {
			type: Boolean,
			required: true
		},
		isWheelPackage: {
			type: Boolean,
			default: false,
			required: true
		},
		showFinancing: {
			type: Boolean,
			default: false,
			required: false
		}
	},

	mixins: [RudderstackCheckoutMixin],

	components: {
		'stateful-btn': statefulBtn,
		MobileInstallForm,
		states,
		provinces,
		AuthviaCreditCard,
		AlertBox
	},

	data() {
		return {
			amount: 0,
			disableSubmit: true,
			isLoading: false,
			status: '',
			useBillingForMobileInstall: false,
			submitted: false,
			billing: {
				firstName: '',
				testFirstName: '',
				lastName: '',
				address: {
					line1: '',
					line2: '',
					city: '',
					state: '',
					zipCode: ''
				},
				deliveryAddress: {
					firstName: '',
					lastName: '',
					line1: '',
					line2: '',
					city: '',
					state: '',
					zipCode: ''
				},
				phone: '',
				email: ''
			},
			card: {
				number: '',
				expired: '',
				cvn: '',
				type: ''
			},
			ccErroring: {
				number: false,
				cvn: false,
				expired: false
			},
			hasFinancing: false
		};
	},

	watch: {
		// defaults billing as MI address
		'$store.state.appointment.appointment': function (newVal, oldVal) { //eslint-disable-line
			if (newVal.mobileInstallZip) {
				this.useBillingForMobileInstall = true;
				this.billing.address.zipCode = newVal.mobileInstallZip;
				this.billing.deliveryAddress.zipCode = newVal.mobileInstallZip;
			}
		},
		'useBillingForMobileInstall': function () {
			if (this.useBillingForMobileInstall) {
				this.billing.address.zipCode = this.appointment.mobileInstallZip;
			} else {
				this.billing.address.zipCode = '';
			}
		},
		newAppointmentChosen(newAppointment) {
			if (newAppointment) {
				this.isLoading = false;
			}
		}
	},

	methods: {
		...mapActions('cart/order', ['setConsumer']),
		...mapMutations('cart/order', ['setFinancing']),

		fillForm() {
			this.amount = 0;
			this.billing = {
				firstName: 'firstName',
				lastName: 'lastName',
				email: this.billing.email,
				address: {
					line1: '7012 Euclid Ave',
					city: 'Cleveland',
					state: 'OH',
					zipCode: '44103'
				},
				phone: '(216)432-0088'
			};

			this.card = {
				number: '4111111111111111',
				expired: '01/30',
				cvn: '123',
				type: '001'
			};
		},
		setInstallZipForBilling() {
			this.billing.address.zipCode = this.details.consumer.deliveryAddress.zipCode;
		},
		handlePaymentMethodSuccess(details) {
			const cardDetails = details.data;

			this.card.number = cardDetails.identifier;
			this.card.expired = `${cardDetails.expirationMonth}/${cardDetails.expirationYear}`;
			this.card.cvn = null;
			this.card.type = cardDetails.cardType;

			this.validateBeforeSubmit();
		},
		validateBeforeSubmit() {
			this.submitted = true;
			this.isLoading = true;

			this.setFinancing(this.hasFinancing);
			// Validate the Consumer Address Inputs
			this.$validator.validateAll().catch((err) => {
				throw new Error(`validate form${ err}`);
			// eslint-disable-next-line complexity
			}).then((isConsumerInfoValid) => {
				// Valid consumer info and credit card. Credit card will not be vaild if it's blank.
				if (isConsumerInfoValid && this.isPaymentSubmittedSuccessful) {
					const consumerInfo = {
						firstName: this.billing.firstName,
						lastName: this.billing.lastName,
						address: {
							line1: this.billing.address.line1,
							line2: '',
							city: this.billing.address.city,
							state: this.billing.address.state,
							zipCode: this.billing.address.zipCode,
							country: 'US'
						},
						email: this.billing.email,
						phone: this.billing.phone.replace(/\D/g, ''),
						cellPhone: '',
						contactPreference: '',
						comments: this.appointment.comments,
						selectedFinancing: this.hasFinancing
					};

					return this.setConsumer(consumerInfo).catch((err) => {
						throw new Error(`validate form${ err}`);
					}).then(() => {
						return this.$emit('validForm', {
							firstName: this.billing.firstName,
							lastName: this.billing.lastName,
							email: this.billing.email,
							phone: this.billing.phone.replace(/\D/g, '')
						});
					}).catch((err) => {
						throw new Error(`validate form${ err}`);
					});
				}

				// Invalid form
				this.isLoading = false;
				this.status = false;

				// Sets dataLayer
				return formErrors(this.createFormErrorObject(isConsumerInfoValid));
			}).catch((err) => {
				throw new Error(`validate form${ err}`);
			});
		},
		createFormErrorObject(isConsumerInfoValid) {
			let formErrorConfig = {
				hasFormErrors: !isConsumerInfoValid,
				formError: this.errors.all(),
				hasCardErrors: false
			};

			return formErrorConfig;
		},
		uppercaseZip() {
			this.billing.address.zipCode = this.billing.address.zipCode.toUpperCase();
		},
		handleStatefulButtonClick: function() {
			const authViaWidget = document.getElementById('avcc');

			authViaWidget.setAttribute('data-form-submit', 'true');
		}
	},

	computed: {
		...mapGetters('appointment', ['isMobileInstallation']),
		...mapState('cart/order', ['leadID', 'signature', 'details']),
		...mapState('cart', ['tiresInCart']),
		...mapState('appointment', ['appointment']),
		...mapState('initial', ['env', 'cybersource']),
		...mapState('dealer', ['selected']),
		...mapState('authvia', [
			'tokenValue',
			'merchantClientID',
			'isCreatingTransaction'
		]),
		...mapGetters('authvia', [
			'isAuthviaReady',
			'isPaymentSubmittedSuccessful',
			'showSuccessfulTransactionMessage'
		]),

		isTestEnv() {
			return !this.env.isProd;
		},
		// eslint-disable-next-line complexity
		financingProductAllowed() {
			return this.tiresInCart.products && this.showFinancing && this.financingDisclaimer.financingCheckbox && !this.isMobileInstallation && !this.isWheelPackage;
		},
		statesList() {
			if (this.env.isCA) {
				return provinces;
			}

			return states;
		},
		// TODO: Is Authvia always a GET or do we still need to account for the potential of a POST?
		// If always a GET, we can re-factor and clean this up some more.
		getMethod() {
			return 'GET';
		},
		getAction() {
			// leave undefined for non-cc. It'll post to this document's url, which is correct.
			return null;
		},
		origin() {
			return `${window.location.origin}/app/checkout/receipt`;
		},
		// This is used to determine if this is a program-related credit card
		isCreditCard() {
			return this.financingDisclaimer && this.financingDisclaimer.disclaimerText2;
		},
		partnerLogoName() {
			if (this.financingDisclaimer) {
				return `${this.$t('message.financing')} ${this.$t('message.availableThrough') } ${ this.financingDisclaimer.description}`;

			}

			return `finance partner logo`;
		},
		mobileInstallInfoChoice() {
			const { firstName, lastName } = this.billing;
			const { line1, city, state, zipCode } = this.billing.address;
			const billingInfo = {
				firstName,
				lastName,
				line1,
				line2: '',
				city,
				state,
				zipCode,
				country: 'US'
			};

			return this.useBillingForMobileInstall
				? billingInfo
				: this.details.consumer.deliveryAddress;
		},
		partnerLogoURL() {
			if (this.financingDisclaimer) {
				return `/dist/assets/finance/${this.financingDisclaimer.code}_logo.png`.toLowerCase();
			}

			return null;
		},
		zipMinLength() {
			if (this.env.isCA) {
				return 6;
			}

			return 5;
		},
		zipMaxLength() {
			if (this.env.isCA) {
				return 7;
			}

			return 5;
		},
		zipRegex() {
			// Check for country specific zip codes
			if (this.env.isCA) {
				return new RegExp(/^(?:[A-Z]\d[A-Z][ -]?\d[A-Z]\d)$/i);
			}

			// Default zip codes to US pattern
			return new RegExp(/^((\d{5}-\d{4})|(\d{5}))$/);
		}
	}
};
</script>
