import axios from 'axios';

const getStateFromSession = (websiteUID) => {
	return axios.get('/service/receiptDataRequest', {
		params: {
			websiteUID
		}
	})
		.then(({ data }) => data)
		.catch((err) => {
			throw new Error(err);
		});
};


// eslint-disable-next-line max-params
const saveStateToSession = async (state, dealer, isWheelPackage, packagePrice) => {
	const { cart, initial, appointment } = state;
	const { details } = cart.order;

	const receipt = {
		consumer: details.consumer,
		hasCreditCardCheckout: initial.hasCreditCardCheckout,
		leadID: details.leadID,
		leadSearchType: details.leadSearchType,
		payment: details.payment,
		pricing: isWheelPackage ? packagePrice : cart.tiresInCart.pricing,
		products: details.products,
		mobileInstallInfo: details.consumer.deliveryAddress,
		vehicle: details.vehicle,
		financing: cart.tiresInCart.financing || {},
		appointment: appointment.appointment,
		dealer
	};

	await axios.post('/service/receiptDataRequest', { receipt: receipt });
};


export {
	getStateFromSession,
	saveStateToSession
};

