import * as actions from './actions.js';

const store = {
	namespaced: true,
	state: {
		filteredProducts: [],
		filterToShow: 'all',
		recigneProducts: [],
		showRecOption: false,
		sortBy: 'default',
		isMobileInstallFilter: false
	},
	getters: {
		filteredProducts: state => state.filteredProducts,
		filterToShow: state => state.filterToShow,
		recigneProducts: state => state.recigneProducts,
		showRecOption: state => state.showRecOption,
		sortBy: state => state.sortBy,
		isMobileInstallFilter: state => state.isMobileInstallFilter
	},
	// Mutations are commited and must be synchronous
	mutations: {
		setFilteredProducts(state, payload) {
			state.filteredProducts = payload;
		},
		setFilterToShow(state, payload) {
			state.filterToShow = payload;
		},
		setRecigneProducts(state, payload) {
			state.recigneProducts = payload;
		},
		setShowRecOption(state, payload) {
			state.showRecOption = payload;
		},
		setSortBy(state, payload) {
			state.sortBy = payload;
		},
		setMobileInstallFilter(state, payload) {
			state.isMobileInstallFilter = payload;
		}
	},
	actions
};

export default store;
