import { contains, toArray } from 'underscore';
import * as actions from './actions.js';
import { setQtySelected } from '@/utils/setDataLayer';
import verify from './modules/verify/index';

const looseQuantity = [4];
const staggeredQuantity = [2, 2];

const store = {
	namespaced: true,
	state: {
		wpks: [],
		allProducts: [],
		singleProduct: [],
		isStaggered: false,
		noTires: false,
		qtySelected: [],
		selectedModelId: null,
		defaultQty: looseQuantity,
		maxToCompare: 3,
		deliveryDate: '',
		oos: false,
		productCart: {},
		showTruck: false,
		selectedProdName: '',
		showDiff: false,
		hideSame: false,
		productSetsToCompare: [],
		singleProductSetToCompare: [],
		specsToCompare: [],
		defaultSpecs: ['financing', 'promos', 'season', 'factoryInstalled', 'oemTireMarkDescription', 'ecoTire',
			'warrantyMilesNumeric', 'warrantyYears', 'roadHazardFlag', 'performanceType', 'isRunflat',
			'gradeTreadWear', 'tractionGrade', 'treadDepth', 'serviceIndex', 'speedRating', 'size', 'sidewall']
	},
	getters: {
		allProducts: state => state.allProducts,
		qtySelected: state => state.qtySelected,
		selectedModelId: state => state.selectedModelId,
		defaultQty: state => state.defaultQty,
		productCart: state => toArray(state.productCart),
		isStaggered: state => state.isStaggered,
		singleProductSetToCompare: state => state.singleProductSetToCompare,
		productSetsToCompare: state => state.productSetsToCompare,
		// Filters through the array of wheel packages to get rid of duplicates based on SKU
		wpks: state => {
			let accu = [],
				accuSku = [];

			state.wpks.forEach(el => {

				// JANKY: We are not rendering both L and R product tiles, just 1 tile for directionals.
				// Therefore, if the SKU ends in 'R', we will not add it to the list.
				// Hopefully, by the grace of lotball, this will be solved next year when we have a solution
				// for directional packages.
				if (el.sku.slice(-1) !== 'R') {
					if (!contains(accuSku && (el.sku.slice(-1) !== 'R'), el.sku.substring(0))) {
						accuSku.push(el.sku.substring(0));
						accu.push(el);
					}
				}
			});

			return accu;
		}
	},
	// Mutations are commited and must be synchronous
	mutations: {
		wpks(state, payload) {
			state.wpks = payload;
		},
		allProducts(state, payload) {
			state.allProducts = payload;
		},
		singleProduct(state, payload) {
			state.singleProduct = payload;
		},
		setDeliveryDate(state, payload) {
			state.deliveryDate = payload;
			state.oos = payload === '';
		},
		checkStaggered(state, skus) {
			state.isStaggered = skus ? skus.split(',').length > 1 : false;
		},
		isStaggered(state, payload) {
			state.isStaggered = payload;
			if (payload) {
				state.defaultQty = staggeredQuantity;
			} else {
				state.defaultQty = looseQuantity;
			}
			// Sets Qty for data layer
			setQtySelected(state.qtySelected);
		},
		setQty(state, qty) {
			state.qtySelected = qty;
		},
		updateQty(state, qtylist) {
			// qty is [4] or [2,2] (or lower numbers, but always [front] or [front,rear])
			state.qtySelected = qtylist;
			// Sets Qty for data layer
			setQtySelected(state.qtySelected);
		},
		productCart(state, payload) {
			state.productCart = payload;
		},
		showTruck(state, payload) {
			state.showTruck = payload;
		},
		noTires(state, payload) {
			state.noTires = payload;
		},
		noTiresContactInfo(state, payload) {
			state.noTiresContactInfo = payload;
		},
		setProductName(state, payload) {
			state.selectedProdName = payload;
		},
		setSelectedModelId(state, payload) {
			state.selectedModelId = payload;
		},
		setShowDiff(state, payload) {
			state.showDiff = payload;
		},
		setHideSame(state, payload) {
			state.hideSame = payload;
		},
		setSpecsToCompare(state, payload) {
			state.specsToCompare = payload;
		},
		setProductSetsToCompare(state, payload) {
			state.productSetsToCompare = payload;
		},
		setSingleProductSetToCompare(state, payload) {
			state.singleProductSetToCompare = payload;
		}
	},
	// Actions are dispatched, which then commit mutations. Can be asynchronous methods.
	actions,
	modules: {
		verify
	}
};

export default store;
