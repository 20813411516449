<template>
    <div>
        <!-- WAITING FOR SUCCESSFUL TRANSACTION MESSAGE LOOP -->
        <div v-if="isCreatingTransaction" class="alert-box alert-wait">

            <!-- Spinner Box -->
            <div>
                <span class="spinner spinner-slow spinner-blue"></span>
            </div>

            <!-- Heading & Description -->
            <div>
                <p class="headline">{{ this.messages[this.activeMessage].headline }}</p>
                <p class="description">{{ this.messages[this.activeMessage].description }}</p>
                <p class="disclaimer">{{ this.messages[this.activeMessage].disclaimer }}</p>
            </div>
        </div>

        <!-- RECIEVED SUCCESSFUL TRANSACTION MESSAGE -->
        <div v-if="showSuccessfulTransactionMessage" class="alert-box alert-success">

            <!-- Success Icon -->
            <div class="confirmation-badge">
                <img src="/dist/assets/svgs/confirmation-badge.svg" alt="Order confirmation badge"/>
            </div>

            <!-- Heading & Description & Disclaimer -->
            <div>
                <p class="headline">{{ $t("message.orderConfirmed") }}</p>
                <p class="description">{{ $t("message.orderSuccessfullyProcessed") }}</p>
                <p class="disclaimer">{{ $t("message.redirectingYou") }}</p>
            </div>
        </div>

        <!-- ERROR MESSAGE-->
        <div v-if="isTransactionError" class="alert-box alert-error">

            <div class="error-icon">
                <img src="/dist/assets/svgs/icon-error.svg" alt="Error Icon"/>
            </div>

            <!-- Heading & Description & Disclaimer -->
            <div>
                <p class="headline">{{ $t("message.paymentNotProcessed") }}</p>
                <p class="description">{{ $t("message.havingTroubleProcessing") }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
	name: 'AlertBox',
	props: {
		isTransactionError: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			activeMessage: 0,
			intervalId: null,
			messages: [
				{
					'headline': this.$t('message.verifyingOrderDetails'),
					'description': this.$t('message.yourOrderSecurelyProcessed'),
					'disclaimer': this.$t('message.dontRefresh')
				},
				{
					'headline': this.$t('message.processingYourPayment'),
					'description': this.$t('message.makingEverythingPerfect'),
					'disclaimer': this.$t('message.dontRefresh')
				},
				{
					'headline': this.$t('message.finalizingYourOrder'),
					'description': this.$t('message.thankYouPatience'),
					'disclaimer': this.$t('message.dontRefresh')
				},
				{
					'headline': this.$t('message.almostThere'),
					'description': this.$t('message.fewMoreMoments'),
					'disclaimer': this.$t('message.dontRefresh')
				},
				{
					'headline': this.$t('message.experiencingHighTraffic'),
					'description': this.$t('message.dueToHighDemand'),
					'disclaimer': this.$t('message.dontRefresh')
				}
			]
		};
	},
	computed: {
		...mapState('authvia', ['isCreatingTransaction']),
		...mapGetters('authvia', ['showSuccessfulTransactionMessage'])
	},
	methods: {
		displayMessage() {
			// set the interval and get the interval ID
			// use that intervalID to cancel the process
			// when the activeMessage count = the size of the
			// message array
			const intervalId = setInterval(() => {
				if ((this.activeMessage === (this.messages.length - 1))) {
					clearInterval(intervalId);
				} else {
					this.activeMessage++;
				}
			}, 3000);
		}
	},
	watch: {
		// check to see if AuthVia is creating a transaction
		// then run the displayMessage method to cycle
		// through the messages in the array of messages.
		isCreatingTransaction: function() {
			this.displayMessage();
		}
	}
};
</script>
