const store = {
	namespaced: true,
	state: {
		hasCreditCardCheckout: false,
		hasSearchBySize: true,
		cybersource: {},
		env: {
			isDemo: false,
			isDev: false,
			isProd: false,
			isQa: false,
			isStaging: false
		},
		typeformSurveyURL: '',
		theme: ''
	},
	getters: {
		getSiteLogo: (state) => {
			return `${window.location.protocol}//${window.location.host}/dist/assets/${state.theme}-logo.png`;
		}
	}
};

const initial = window.__INITIAL_STATE__; // eslint-disable-line no-underscore-dangle

if (initial) {
	store.state = initial;
}


export default store;
