const store = {
	namespaced: true,

	state: {
		token: '',
		tokenValue: '',
		merchantClientID: '',
		paymentMethodSuccess: {},
		isCreatingTransaction: false,
		isCreateTransactionSuccessful: false
	},

	getters: {
		isAuthviaReady(state) {
			return state.tokenValue !== '' && state.merchantClientID !== '';
		},
		isPaymentSubmittedSuccessful(state) {
			return state.paymentMethodSuccess && Object.keys(state.paymentMethodSuccess).length > 0;
		},
		paymentMethodID(state) {
			return state.paymentMethodSuccess.data.id;
		},
		showSuccessfulTransactionMessage(state) {
			return state.isCreateTransactionSuccessful && !state.isCreatingTransaction;
		}
	},

	mutations: {
		setAuthviaToken(state, payload) {
			state.token = payload;
		},
		setAuthviaTokenValue(state, payload) {
			state.tokenValue = payload;
		},
		setAuthviaMerchantClientID(state, payload) {
			state.merchantClientID = payload;
		},
		setPaymentMethodSuccess(state, payload) {
			state.paymentMethodSuccess = payload;
		},
		setIsCreatingTransaction(state, payload) {
			state.isCreatingTransaction = payload;
		},
		setIsCreateTransactionSuccessful(state, payload) {
			state.isCreateTransactionSuccessful = payload;
		}
	},

	actions: {

		/**
		 * This is called on our initial setup after getting the customer token (Payment.vue).
		 * It populates this store module and the browsers window.sessionStorage
		 *
		 * @param {Object} payload - the customer token object we get back
		 * @returns {void}
		 */
		setupAuthviaTokenValues({ commit }, payload) {
			commit('setAuthviaToken', payload.token);
			commit('setAuthviaTokenValue', payload.tokenValue);
			commit('setAuthviaMerchantClientID', payload.merchantClientID);

			window.sessionStorage.setItem('authviaToken', JSON.stringify(payload.token));
			window.sessionStorage.setItem('authviaTokenValue', payload.tokenValue);
			window.sessionStorage.setItem('authviaMerchantClientID', payload.merchantClientID);
		},
		clearAuthviaSessionStorage() {
			sessionStorage.removeItem('authviaToken');
			sessionStorage.removeItem('authviaTokenValue');
			sessionStorage.removeItem('authviaMerchantClientID');
		}
	}
};

export default store;
