import { format } from 'date-fns';

// Keeps track of all the local component data
let appData = {
	// Start at -1 so the first time it's run it will be 0
	sortFilterId: -1,
	sortBy: '',
	show: '',
	tireCoachFilters: {
		milesDriven: 3,
		driveStyle: 3,
		weatherType: [],
		recommendWinter: false,
		truckOpts: {}
	},
	qtySelected: [4],
	impressionID: Math.floor((new Date()).getTime() / 1),
	additionalServices: []
};

const incrementSortFilterId = () => {
	appData.sortFilterId ++;
};

const setAdditionalServices = services => {
	appData.additionalServices = services;
};

const setSortby = filter => {
	appData.sortBy = filter;
};

const setShow = filter => {
	appData.show = filter;
};

const setQtySelected = qty => {
	appData.qtySelected = qty;
};

const setTireCoachFilters = filters => {
	appData.tireCoachFilters = filters;
};

// Maps mile IDs to a string
const mapMiles = miles => {
	const milesMap = {
		1: 'Under 5k',
		2: '7,500k',
		3: '10k',
		4: '12,500k',
		5: 'Over 15k'
	};

	return milesMap[miles];
};

// Maps driving style IDs to a string
const mapDrivingStyle = style => {
	const driveStyle = {
		1: 'Slow & Steady',
		2: 'Easygoing',
		3: 'Typical',
		4: 'Spirited',
		5: 'Fast & Lively'
	};

	return driveStyle[style];
};

// Maps the weather IDs to a string
const mapWeather = (weather) => {
	const weatherMap = {
		1: 'Hot sunny days',
		2: 'Occasional rain',
		3: 'Frequent rain',
		4: 'Snow, rain, ice',
		5: 'Extreme winter'
	};

	return weather.reduce( (accu, val) => {
		return accu ? `${accu}|${weatherMap[val]}` : `${weatherMap[val]}`;
	}, '' );
};

// Loops activities object and outputs true values to a string
const mapActivites = activities => {
	let str = '';

	for (const activity in activities) {
		if (activities[activity]) {
			str += `${activity}|`;
		}
	}

	return str;
};

/**
 * CreateString returns a formatted string and seperates multiple values with a pipe
 * @param {Array} dataArray is the array that will be iterated over to grab values
 * @param {String} key - The key of the object
 */

const createString = (dataArray, key) => {
	return dataArray.reduce( (accu, val) => {
		return accu ? `${accu}|${val[key]}` : `${val[key]}`;
	}, '');
};

const setStaggeredPosition = (index) => {
	return index > 0 ? 'Rear' : 'Front';
};

const leavingPage = ({url}) => {
	return window.dataLayer.push({
		event: 'leavingPage',
		url
	});
};

const bySizeSearchLoaded = ({ make, width, ratio, diameter, size }) => {
	const properties = {
		make,
		width,
		ratio,
		diameter,
		size
	};

	return window.dataLayer.push({
		event: 'bySizeSearchLoaded',
		...properties
	});
};

const byVehicleSearchLoaded = ({ year, make, model, trim, option = '' }) => {
	const properties = {
		year,
		make,
		model,
		trim,
		option
	};

	return window.dataLayer.push({
		event: 'byVehicleSearchLoaded',
		...properties
	});
};

const sortByChoice = (sortChoice) => {
	return window.dataLayer.push({
		event: 'sortByChoice',
		selectionValue: sortChoice
	});
};

const showChoice = (value) => {
	return window.dataLayer.push({
		event: 'showChoice',
		selectionValue: value
	});
};

const filterSelected = (filter) => {

	let selectedFilter = filter;

	if (selectedFilter.hasOwnProperty('milesDriven')) {
		selectedFilter = mapMiles(filter.milesDriven);
	}

	if (selectedFilter.hasOwnProperty('drivingStyle')) {
		selectedFilter = mapDrivingStyle(filter.drivingStyle);
	}

	return window.dataLayer.push({
		event: 'filterChoice',
		selectionValue: selectedFilter
	});
};

const filterDeselected = (filter) => {

	return window.dataLayer.push({
		event: 'filterChoiceDeselect',
		selectionValue: filter
	});
};

const filterChoice = (filter, type, isChecked) => {
	return !isChecked && type === 'checkbox' ? filterDeselected(filter) : filterSelected(filter);
};


const orderSubmitSuccessPerSku = ({ payment, products}) => {
	products.forEach( (product, index) => {
		if (window.dataLayer) {
			window.dataLayer.push({
				event: 'orderSubmitSuccessPerSku',
				sku: product.sku,
				qty: product.qty,
				consumerPromo: product.promotions ? createString(product.promotions, 'promoID') : '',
				tirePrice: product.retailPrice,
				installedPrice: product.installPrice,
				finalPrice: payment.grandTotalAmount,
				labelOriginalEquipment: product.factoryInstalled,
				productRoadHazardCoverage: product.roadHazardFlag,
				staggeredFlag: products.length > 1 ? setStaggeredPosition(index) : ''
			});
		}
	});
};

const orderSubmitSuccessSummary = ({ leadID, details }) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'orderSubmitSuccessSummary',
			leadNumber: leadID,
			finalPriceTotal: details.payment.grandTotalAmount,
			additionalServices: createString(details.services, 'title'),
			additionalServicesPrice: createString(details.services, 'price')
		});
	}
};

const orderSubmitAlert = ({ alertCategory, alertMessage}) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'orderSubmitAlert',
			alertCategory,
			alertMessage
		});
	}
};

const consumerReceiptGenerated = ({ leadNumber, finalPriceTotal, additionalServices, appointmentType, referrerType, qty, isStaggered, isWheelPackage }) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'consumerReceiptGenerated',
			leadNumber,
			finalPriceTotal,
			additionalServices: createString(additionalServices, 'title'),
			additionalServicesPrice: createString(additionalServices, 'price'),
			transportation: appointmentType,
			referrerType,
			qty,
			isStaggered,
			isWheelPackage
		});
	}
};

// eslint-disable-next-line complexity
const formErrors = ({ hasFormErrors, hasCardErrors, formError, cardType, isExpired, isValidCVV }) => {
	if (hasFormErrors && window.dataLayer) {
		window.dataLayer.push({
			event: 'orderSubmitAlert',
			alertCategory: 'User Error',
			alertMessage: formError.reduce((accu, e) => {
				return `${accu}${e.replace('.', '|')}`;
			}, ``)
		});
	}

	if (hasCardErrors && window.dataLayer) {
		window.dataLayer.push({
			event: 'orderSubmitAlert',
			alertCategory: 'CC Error',
			alertMessage: `cardType:${cardType}|validCVV:${isValidCVV}|expired:${isExpired}`
		});
	}

};

const dealerImpression = (dealers) => {
	dealers.forEach(({ zip, distance, amenities, program }) => {
		if (window.dataLayer) {
			window.dataLayer.push({
				event: 'dealerImpression',
				dealerLocation: zip.toString() || '',
				dealerDistance: distance,
				dealerAmenities: createString(amenities, 'description'),
				dealerCode: program.code
			});
		}
	});
};

const dealerUseCurrentLocationSuccess = (location) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'dealerUseCurrentLocationSuccess',
			location,
			searchMethod: 'Click'
		});
	}
};

const dealerEnterZipSuccess = ({ location, dealerRadius }) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'dealerEnterZipSuccess',
			location,
			dealerRadius,
			searchMethod: 'Click'
		});
	}
};

const dealerEnterZipAlert = ({ alertUserEntry, alertCategory, alertMessage }) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'dealerEnterZipAlert',
			alertCategory,
			alertMessage,
			alertUserEntry
		});
	}
};

const staggeredConfirmation = ({ sizeQuestion, size }) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'staggeredConfirmation',
			sizeQuestion: sizeQuestion ? 'Yes' : 'No',
			size
		});
	}
};

const productTileImpression = ({ productSets, sortBy, show }) => {
	const { milesDriven, drivingStyle, weatherType, recommendWinter, truckOpts } = appData.tireCoachFilters;
	const filterMiles = mapMiles(milesDriven);
	const filterDrivingStyle = mapDrivingStyle(drivingStyle);
	const filterWeather = mapWeather(weatherType);
	const filterOtherActivities = mapActivites(truckOpts);

	// Set the dropdown values so we can grab at a later time for productTileChoose
	setSortby(sortBy);
	setShow(show);
	incrementSortFilterId();

	// Loop the entire productSet
	productSets.forEach((productSet, tileIndex) => {
		// Loop the individual products
		productSet.products.forEach((product, index) => { // eslint-disable-line complexity
			if (window.dataLayer) {
				window.dataLayer.push({
					event: 'productTileImpression',
					searchResultLoadedImpressionTimeFreeze: appData.impressionID,
					filterSortByDropdown: sortBy,
					filterShowDropdown: show,
					sortFilterId: appData.sortFilterId,
					qty: appData.qtySelected[index],
					filterMiles,
					filterWeather,
					filterDrivingStyle,
					filterOtherActivities,
					filterWinterTires: recommendWinter,
					sku: product.sku,
					consumerPromo: product.promotions ? createString(product.promotions, 'promoID') : '',
					tirePrice: product.retailPrice,
					installedPrice: product.installPrice,
					labelOriginalEquipment: product.factoryInstalled,
				labelLocalBestSeller: productSet.localBestSeller ? true : false, // eslint-disable-line
					productRoadHazardCoverage: product.roadHazardFlag,
					staggeredFlag: productSet.products.length > 1 ? setStaggeredPosition(index) : '',
					productTilePosition: tileIndex + 1,
					finalPrice: Number(productSet.pricing.priceAfterDiscounts)
				});
			}
		});
	});
};

const productTileChoose = ({ productSet, tilePosition }) => {
	const { milesDriven, drivingStyle, weatherType, recommendWinter, truckOpts } = appData.tireCoachFilters;
	const filterMiles = mapMiles(milesDriven);
	const filterDrivingStyle = mapDrivingStyle(drivingStyle);
	const filterWeather = mapWeather(weatherType);
	const filterOtherActivities = mapActivites(truckOpts);

	productSet.products.forEach((product, index) => { // eslint-disable-line complexity
		if (window.dataLayer) {
			window.dataLayer.push({
				event: 'productTileChoose',
				searchResultLoadedImpressionTimeFreeze: appData.impressionID,
				filterSortByDropdown: appData.sortBy,
				filterShowDropdown: appData.show,
				sortFilterId: appData.sortFilterId,
				qty: appData.qtySelected[index],
				filterMiles,
				filterWeather,
				filterDrivingStyle,
				filterOtherActivities,
				filterWinterTires: recommendWinter,
				sku: product.sku,
				consumerPromo: product.promotions ? createString(product.promotions, 'promoID') : '',
				tirePrice: product.retailPrice,
				installedPrice: product.installPrice,
				labelOriginalEquipment: product.factoryInstalled,
			labelLocalBestSeller: productSet.localBestSeller ? true : false, // eslint-disable-line
				productRoadHazardCoverage: product.roadHazardFlag,
				staggeredFlag: productSet.products.length > 1 ? setStaggeredPosition(index) : '',
				productTilePosition: tilePosition + 1,
				finalPrice: Number(productSet.pricing.priceAfterDiscounts)
			});
		}
	});
};

const submitAppointmentTypeOk = ( appointmentType ) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'appointmentTypeOk',
			appointmentType
		});
	}
};

const submitAppointmentDateOk = ( appointmentDate ) => {
	let apptDateString = format(new Date(appointmentDate), 'MM/d/yyyy');

	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'appointmentDateOk',
			appointmentDate: apptDateString
		});
	}
};

const submitAppointmentTimeOk = ( appointmentTime ) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'appointmentTimeOk',
			appointmentTime
		});
	}
};

const scheduleOk = ({ appointmentDate, transportation, appointmentTime, provider }) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'scheduleOk',
			transportation,
			provider,
			appointmentDate: format(new Date(appointmentDate), 'MM/d/yyyy'),
			appointmentTime
		});
	}
};

const additionalServicesAdded = (services) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'additionalServicesSet',
			additionalServices: services.length ? createString(services, 'title') : '',
			additionalServicesPrice: services.length ? createString(services, 'price') : ''
		});
	}
};

const scheduleProviderSet = (provider) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'scheduleProviderSet',
			provider
		});
	}
};

const schedule500Error = (provider) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'schedule500Error',
			provider
		});
	}
};

const scheduleReferrerType = (referrer) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'scheduleReferrerType',
			referrerType: referrer
		});
	}
};

const selectTransportationOption = (transportationID) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'selectTransportationOption',
			transportationID: transportationID

		});
	}
};

const scheduleOOSError = ({referrer, sku}) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'scheduleOOSError',
			referrer,
			sku
		});
	}
};

const zipCodeVerify = ({ dealerZipCode, zipNotAvailable }) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'zipCodeVerify',
			dealerZipCode,
			customerZipCode: zipNotAvailable
		});
	}
};

// Fires when the user selects the "choose" button on the dealer page
const dealerChooseSuccess = ({ dealerAmenities, dealerZip, dealerCode }) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'dealerChooseSuccess',
			dealerLocation: dealerZip ? dealerZip.toString() : '',
			dealerAmenities: dealerAmenities.length ? createString(dealerAmenities, 'name') : '',
			dealerCode: dealerCode
		});
	}
};

// Fires in the event of an unavailable dealer or a service error
const dealerChooseAlert = ({ alertCategory, alertMessage}) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'dealerChooseAlert',
			alertCategory,
			alertMessage
		});
	}
};

// Fires when the checkout page loads product data
const cartImpression = ({ products, qty, localBest}) => {
	products.forEach((product, index) => {
		if (window.dataLayer) {
			window.dataLayer.push({
				event: 'cartImpression',
				sku: product.sku,
				qty: qty[index],
				consumerPromo: product.promotions ? createString(product.promotions, 'promoID') : '',
				tirePrice: product.retailPrice,
				installedPrice: product.installPrice,
				labelOriginalEquipment: product.factoryInstalled,
				labelLocalBestSeller: localBest,
				productRoadHazardCoverage: product.roadHazardFlag,
				staggeredFlag: products.length > 1 ? setStaggeredPosition(index) : ''
			});
		}
	});
};

const evoxImageSeenInViewport = () => {
	return window.dataLayer.push({
		event: 'evoxImageSeenInViewport'
	});
};

const paymentPageError = ( provider ) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'paymentPageError',
			calendarIntegrationType: provider
		});
	}
};

const noDataEvent = (eventName) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: eventName
		});
	}
};

const productSkuEvent = (eventName, productSet) => {
	productSet.products.forEach((product) => {
		if (window.dataLayer) {
			window.dataLayer.push({
				event: eventName,
				sku: product.sku
			});
		}
	});

};

const comparePageImpression = (productSets) => {
	productSets.forEach((productSet) => {
		productSet.products.forEach((product) => {
			if (window.dataLayer) {
				window.dataLayer.push({
					event: 'comparePageImpression',
					sku: product.sku
				});
			}
		});
	});
};

const scheduleMobileInstallButton = (url, tires) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'scheduleMobileInstallButton',
			url: url,
			sku: tires.products.map((product) => product.sku)
		});
	}
};

const scheduleMobileInstallDisabledButton = (url, tires) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'scheduleMobileInstallDisabledButton',
			url: url,
			sku: tires.products.map((product) => product.sku)
		});
	}
};

const scheduleMobileInstallGetStarted = (url, tires) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'scheduleMobileInstallGetStarted',
			url: url,
			sku: tires.products.map((product) => product.sku)
		});
	}
};

const productMobileInstallDropdown = (url, productSet) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'productMobileInstallDropdown',
			url: url,
			sku: productSet.products.map((product) => product.sku)
		});
	}
};

const detailsMobileInstallDropdown = (url, productSet) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'detailsMobileInstallDropdown',
			url: url,
			sku: productSet.products.map((product) => product.sku)
		});
	}
};

const filterMobileInstallToggle = (url) => {
	if (window.dataLayer) {
		window.dataLayer.push({
			event: 'filterMobileInstallToggle',
			url: url
		});
	}
};

export {
	orderSubmitSuccessPerSku,
	orderSubmitSuccessSummary,
	formErrors,
	dealerImpression,
	orderSubmitAlert,
	consumerReceiptGenerated,
	dealerEnterZipSuccess,
	dealerUseCurrentLocationSuccess,
	dealerEnterZipAlert,
	staggeredConfirmation,
	setTireCoachFilters,
	productTileImpression,
	productTileChoose,
	setQtySelected,
	submitAppointmentTypeOk,
	submitAppointmentDateOk,
	submitAppointmentTimeOk,
	scheduleOk,
	additionalServicesAdded,
	schedule500Error,
	scheduleOOSError,
	scheduleProviderSet,
	selectTransportationOption,
	scheduleReferrerType,
	zipCodeVerify,
	setAdditionalServices,
	dealerChooseSuccess,
	dealerChooseAlert,
	evoxImageSeenInViewport,
	cartImpression,
	bySizeSearchLoaded,
	byVehicleSearchLoaded,
	paymentPageError,
	noDataEvent,
	productSkuEvent,
	comparePageImpression,
	sortByChoice,
	showChoice,
	filterChoice,
	leavingPage,
	scheduleMobileInstallButton,
	scheduleMobileInstallDisabledButton,
	scheduleMobileInstallGetStarted,
	productMobileInstallDropdown,
	detailsMobileInstallDropdown,
	filterMobileInstallToggle
};
