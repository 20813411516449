import { productTileImpression } from '@/utils/setDataLayer';
import { resultsSortedByPreferredBrand } from '@/utils/preferred-brand-sorting';
import * as filtering from '@/utils/filtering';
import * as sorting from '@/utils/sorting';
import recigne from '@dealertire/recigne';

export const handleRecigneResponse = ({ commit, dispatch }, response) => {
	const showRecOption = Boolean(response.suggested.length);

	if (showRecOption) {
		commit('setSortBy', 'recommended');
	} else {
		commit('setSortBy', 'default');
	}

	commit('setShowRecOption', showRecOption);
	commit('setRecigneProducts', response.all);
	commit('setFilteredProducts', response.all);
	dispatch('runShowFilter');
};

export const pushDataLayer = ({ state }) => {
	productTileImpression({
		productSets: state.filteredProducts,
		sortBy: state.sortBy,
		show: state.filterToShow
	});
};

export const runRecigne = ({ dispatch, rootState }, filters) => {
	const allProducts = rootState.products.allProducts;

	if (filters.recommendWinter) {
		filters.rules = ['weather', 'truck', 'drivingStyle'];
	} else {
		filters.rules = ['weather', 'truck', 'drivingStyle', 'distance'];
	}

	dispatch('handleRecigneResponse', recigne(allProducts, filters));
};

export const runSortBy = ({ commit, dispatch, state }) => {
	const { filteredProducts, sortBy } = state;
	let reversed = sortBy === 'zToA' || sortBy === 'highToLow';
	let sortType = {
		aToZ: 'brand',
		zToA: 'brand',
		lowToHigh: 'price',
		highToLow: 'price',
		recommended: 'recommended',
		default: 'oemSeasonPriceBrand',
		treadwearWarranty: 'warranty'
	};
	const sortedProducts = sorting[sortType[sortBy]](filteredProducts, reversed);

	if (sortBy === 'default') {
		commit('setFilteredProducts', resultsSortedByPreferredBrand(sortedProducts));
	} else {
		commit('setFilteredProducts', sortedProducts);
	}

	dispatch('pushDataLayer');
};

export const runShowFilter = ({ commit, dispatch, state }) => {
	const { filterToShow, recigneProducts, isMobileInstallFilter } = state;

	let filteredProducts = recigneProducts;

	if (filterToShow !== 'all') {
		filteredProducts = filtering[filterToShow](recigneProducts);
	}
	if (isMobileInstallFilter === true) {
		filteredProducts = filtering['mobileinstall'](filteredProducts);
	}

	commit('setFilteredProducts', filteredProducts);

	dispatch('runSortBy');
};
