<template>
    <article class="product-item">
        <!-- Recommended Banner -->
        <div v-if="productSet.recommended" class="row">
            <div class="col-12">
                <div class="tile__recommended-wrapper">
                    <span class="tile__recommended-icon">
                        <i class="icon icon--thumbs-up"></i>
                    </span>
                </div>
            </div>
        </div>

        <modal-tire-image
            :isOpen="showTireImageModal"
            @onClose="toggleTireImageModal"
            :productSet="productSet"
            data-cy="productImageModal"
        />

        <div class="row">
            <div class="col-md-12">
                <div :class="['tile', { 'tile--recommended': productSet.recommended }]">
                    <div class="row">
                        <!-- Tire Image -->
                        <div class="col-2 tile__img">
                            <div class="tile__tire-img-wrapper">
                                <button
                                    class="tile__img-button"
                                    v-if="showImageButton"
                                    tabindex="0"
                                    @click.prevent="toggleTireImageModal"
                                    data-cy="productTileImageButton"
                                    :aria-label="`${frontTire.brand} ${catalogEntryDescription} ${frontTire.productId}`"
                                >
                                    <img
                                        class="tile__tire-img"
                                        :src="productImg"
                                        :alt="frontTire.brand"
                                        @error="hideImageButton"
                                    />
                                    <i class="icon icon--search tile__icon"></i>
                                </button>
                            </div>
                        </div>

                        <!-- Tire Info -->
                        <div class="col-5 tile__info">
                            <div class="row tile__info-wrapper">
                                <!-- Branding -->
                                <div class="col-12">
                                    <h5 class="tile__tire-brand">{{ frontTire.brand }}</h5>
                                    <a
                                        :aria-label="catalogEntryDescription"
                                        class="tile__tire-name"
                                        :href="detailsURL"
                                        @click.prevent="goToDetail"
                                    >
                                        {{ catalogEntryDescription }}
                                    </a>
                                </div>

                                <ul v-if="hasFlags" class="col-md-12 tile__badges">
                                    <li
                                        v-if="productSet.localBestSeller"
                                        class="tile__local-best-seller"
                                    >
                                        {{ $t("message.localBestSeller") }}
                                    </li>
                                    <li
                                        v-if="frontTire.factoryInstalled"
                                        class="tile__original-equipment"
                                    >
                                        {{ $t("message.originalEquipment") }}
                                    </li>
                                    <li
                                        v-if="frontTire.oemTireMarkDescription"
                                        class="tile__oem-tiremark"
                                    >
                                        {{ frontTire | fullTireMark }}
                                    </li>
                                </ul>

                                <!-- Specs -->
                                <div class="col-md-12 tile__specs">
                                    <ul class="tile__specs-list">
                                        <li
                                            class="tile__specs-item"
                                            v-for="spec in specs"
                                            :key="spec"
                                        >
                                            {{ spec }}
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <!-- Compare Checkbox-->
                            <div v-if="showCompareCheckbox" class="col-12 tile__compare">
                                <label
                                    :class="[
                                        'tile__compare-label',
                                        {
                                            'tile__compare-label--disabled': this.disableCompareInput,
                                        },
                                    ]"
                                >
                                    <input
                                        class="tile__compare-input"
                                        :checked="isSelectedToCompare"
                                        :disabled="disableCompareInput"
                                        type="checkbox"
                                        :aria-label="$t('message.compare') + ' ' + frontTire.brand + ' ' + catalogEntryDescription"
                                        @change="updateCompare"
                                    />
                                    {{ $t("message.compare") }}
                                </label>
                            </div>
                        </div>

                        <!-- Promotions/Pricing/Qty -->
                        <tile-shop-block
                            :query="query"
                            :tile-position="tileIndex"
                            :product-set="productSet"
                            :financing-disclaimer="financingDisclaimer"
                            :isMobileInstallAvailable="isMobileInstallAvailable"
                            :tire="`${frontTire.brand} ${frontTire.catalogEntryDescription}`"
                        >
                        </tile-shop-block>
                    </div>
                </div>
            </div>
        </div>
    </article>
</template>

<script>
import ModalTireImage from '@/components/ModalTireImage.vue';
import prettyLgNum from '@/filters/pretty-lg-numbers/index.js';
import TileShopBlock from '@/components/TileShopBlock.vue';
import { mapActions, mapState } from 'vuex';
import { contains, extend, find, map } from 'underscore';
import { fullTireMarkDescription } from '@/filters/oem-tire-mark';
import { detailsPageURL } from '@/utils/details-page-url';
import { productSkuEvent } from '@/utils/setDataLayer';

export default {
	name: 'ProductTile',

	components: {
		ModalTireImage,
		TileShopBlock
	},

	props: {
		productSet: {
			type: Object,
			required: true
		},
		query: {
			type: Object,
			required: true
		},
		showCompareCheckbox: {
			type: Boolean,
			required: true
		},
		tileIndex: {
			type: Number,
			required: true
		},
		financingDisclaimer: {
			type: Object
		},
		isMobileInstallAvailable: {
			type: Boolean
		}
	},

	filters: {
		fullTireMark(tire) {
			return fullTireMarkDescription(tire);
		}
	},

	data() {
		return {
			frontTire: {},
			showImageButton: true,
			showTireImageModal: false,
			specs: []
		};
	},

	methods: {
		...mapActions('products', [
			'addProductSetToCompare',
			'removeProductSetFromCompare',
			'setSingleProduct'
		]),
		goToDetail() {
			this.setSingleProduct({
				query: this.query,
				id: this.frontTire.productId,
				dealer: this.selected,
				cid: this.query.cid
			}).then(() => {
				this.$router.push({
					path: '/app/details',
					query: extend(this.query, {
						sku: this.productSet.products.length > 1
							? this.productSet.products.map((prod) => prod.sku).join()
							: this.productSet.products[0].sku,
						qty: this.qtySelected.length
							? this.qtySelected.join(',')
							: this.defaultQty,
						id: this.frontTire.productId,
						localBest: this.productSet.localBestSeller
					})
				});
			});
		},
		hideImageButton() {
			this.showImageButton = false;
		},
		toggleTireImageModal() {
			this.showTireImageModal = !this.showTireImageModal;
		},
		// Updates productSetsToCompare each time the compare checkbox is changed
		updateCompare() {
			if (this.isSelectedToCompare) {
				this.removeProductSetFromCompare(this.productSet);
			} else {
				this.addProductSetToCompare(this.productSet);
				productSkuEvent('productResultsEngageCompareCheckbox', this.productSet);
			}
		}
	},

	computed: {
		...mapState('dealer', ['selected']),
		...mapState('products', ['maxToCompare', 'productSetsToCompare', 'defaultQty', 'qtySelected']),
		detailsURL() {
			return detailsPageURL(this.query, this.productSet);
		},
		disableCompareInput() {
			return (
				this.productSetsToCompare.length >= this.maxToCompare &&
				!this.isSelectedToCompare
			);
		},
		catalogEntryDescription() {
			return this.frontTire.catalogEntryDescription;
		},
		hasFlags() {
			return (
				this.productSet.localBestSeller ||
				this.frontTire.factoryInstalled ||
				this.frontTire.oemTireMarkDescription
			);
		},
		hasWarranty() {
			return Number(this.frontTire.warrantyMilesNumeric)
				? `${prettyLgNum(this.frontTire.warrantyMilesNumeric)} ${this.$t(
					'message.warrantyMiles'
				)}`
				: '';
		},
		isFuelEfficient() {
			return this.frontTire.ecoTire ? this.$t('message.fuelEfficient') : '';
		},
		isRunFlat() {
			return this.frontTire.isRunflat ? this.$t('message.runFlat') : '';
		},
		seasonDescription() {
			const [front] = this.productSet.products;

			const [message] = find(
				[
					[
						'message.allSeasonTires',
						['all-weather', 'all season'].includes(front.season.toLowerCase())
					],
					['message.summerTires', front.season.toLowerCase() === 'summer'],
					['message.winterTires', front.winter]
				],
				([, rule]) => rule
			);

			return message ? this.$t(message) : '';
		},
		isSelectedToCompare() {
			return contains(this.tireIds, this.frontTire.productId);
		},
		productImg() {
			return this.frontTire.imageId
				? `/content/drsg/b_iso/${this.frontTire.imageId}_b.jpg`
				: '/content/drsg/b_iso/default.jpg';
		},
		showRoadHazard() {
			return this.frontTire.showRoadHazard ? this.$t('message.roadHazard') : '';
		},
		tireIds() {
			return map(this.productSetsToCompare, function(selectedProductSet) {
				return selectedProductSet.products[0].productId;
			});
		}
	},

	created() {
		const [front, rear] = this.productSet.products;

		this.frontTire = front;

		let specs = [
			this.seasonDescription,
			this.isRunFlat,
			this.hasWarranty,
			this.showRoadHazard,
			this.isFuelEfficient,
			`${front.size} | ${front.serviceDescription}`
		];

		if (rear) {
			specs.push(`${rear.size} | ${rear.serviceDescription}`);
		}

		this.specs = specs;
	}
};
</script>
