<template>
    <div class="header-cell" role="gridcell">
        <!-- REMOVE COLUMN BUTTON -->
        <div class="header-cell__close-wrapper">
            <button
                class="header-cell__close"
                :name="removeFromCompare"
                :aria-label="removeFromCompare"
                @click.prevent="removeProductSetFromCompare(productSet)"
            >
                <span v-if="!isMobile">
                    {{ $t("message.remove") }}
                </span>
                <i class="icon icon--close" />
            </button>
        </div>

        <div class="header-cell__wrapper">

            <!-- ENLARGED IMAGE MODAL -->
            <modal-tire-image
                @onClose="toggleTireImageModal"
                :isOpen="showTireImageModal"
                :productSet="productSet"
                aria-hidden="true"
            />

            <!-- TIRE IMAGE -->
            <button
                class="header-cell__modal-button"
                @click.prevent="toggleTireImageModal"
                :name="headerTireImgAltText"
            >
                <div
                    role="img"
                    :aria-label="headerTireImgAltText"
                    class="header-cell__img-wrapper"
                    :style="{'background-image': `url(${headerTireImg})`}"
                    :title="headerTireImgAltText"
                    @error="errorLoading = true"
                >
                </div>
            </button>

            <!-- PRODUCT INFO WRAPPER -->
            <div class="header-cell__info-wrapper">

                <!-- BRAND, TIRE MODEL, and PRICE DETAILS-->
                <div class="header-cell__info-details">
                    <!-- BRAND -->
                    <p class="header-cell__tire-brand" role="columnheader">
                        {{ productSet.products[0].brand }}
                    </p>

                    <!-- TIRE MODEL -->
                    <p class="header-cell__tire-name" role="columnheader">
                        <a
                            class="header-cell__link"
                            @click.prevent="goToDetail(productSet)"
                            :href="detailsURL"
                            :title="productDetailsTitle"
                        >
                        {{ productSet.products[0].catalogEntryDescription }}</a
                        >
                    </p>

                    <!-- STAGGERED PRICE -->
                    <template v-if="isStaggered">
                        <p>
                            <span>{{ $t("message.front") }}: </span>
                            <span class="header-cell__price">
                                <strong>
                                    {{ $n(productSet.products[0].pricing.unitInstallPrice , 'currency') }}
                                </strong>
                                {{ $t("message.each") }}
                            </span>
                        </p>

                        <p>
                            <span>{{ $t("message.rear") }}: </span>
                            <span class="header-cell__price">
                                <strong>
                                    {{ $n(productSet.products[1].pricing.unitInstallPrice , 'currency') }}
                                </strong>
                                {{ $t("message.each") }}
                            </span>
                        </p>
                    </template>

                    <!-- LOOSE PRICE -->
                    <p v-else class="header-cell__price">
                        <strong>
                            {{ $n(productSet.products[0].pricing.unitInstallPrice , 'currency') }}
                        </strong>
                        {{ $t("message.each") }}
                    </p>

                    <!-- PROMO TOTAL PRICE -->
                    <template v-if="productSet.promotions">
                        <p
                            class="header-cell__price-total"
                            :class="{ 'header-cell__price-discount': hasAppliedPromo }"
                        >
                            {{ $t(tireMessage) }}
                            <strong>
                                {{
                                    $n(productSet.pricing.installPriceAfterSavings , 'currency')
                            }}</strong
                            >
                        </p>

                        <!-- Only show crossout price if it does not match the final price
							it should never be MORE, but if so, show it so we can fix it. -->
                        <span
                            v-if="
                                productSet.pricing.installPrice !==
                                    productSet.pricing.installPriceAfterSavings
                            "
                            class="header-cell__price-was"
                        >
                            {{ $t("message.was") }}:
                            {{ $n(productSet.pricing.installPrice, 'currency') }}
                        </span>

                        <!-- TAX DISCLAIMER -->
                        <p class="header-cell__tax">{{ $t("message.fullInstallNoTax") }}</p>
                    </template>

                    <!-- NO PROMO TOTAL PRICE -->
                    <span v-else class="header-cell__price-total">
                        {{ $t(tireMessage)
                        }}{{  $n(productSet.pricing.installPrice, 'currency') }}
                    </span>
                    <span v-if="isMobileInstallAvailable && isAvailableFacingWarehouse" class="header-cell__mobileinstall">
                        {{ $t("message.mobileInstallEligible") }}
                    </span>
                </div>

                <!-- ADD TO CART BLOCK -->
                <shop :productSet="productSet" :position="position" />

            </div>
        </div>
    </div>
</template>

<script>
import Shop from '@/components/CompareHeaderCellShop.vue';
import ModalTireImage from '@/components/ModalTireImage.vue';

import { mapActions, mapState } from 'vuex';
import { extend, isEmpty } from 'underscore';
import { productSkuEvent } from '@/utils/setDataLayer';
import { structuredProductQuery } from '@/utils/product-query-builder';
import { detailsPageURL } from '@/utils/details-page-url';
import { accessibilitySpecKeysAndTitles } from '@/utils/specification-conversion.js';

export default {
	props: {
		productSet: {
			type: Object,
			required: true
		},
		selectedDealer: {
			type: Object,
			required: true
		},
		isMobile: {
			type: Boolean,
			required: true
		},
		position: {
			type: Number,
			required: true
		}
	},

	components: {
		Shop,
		ModalTireImage
	},
	data() {
		return {
			errorLoading: false,
			showTireImageModal: false
		};
	},

	computed: {
		...mapState('products', ['isStaggered', 'qtySelected', 'defaultQty']),
		detailsURL() {
			return detailsPageURL(this.$route.query, this.productSet);
		},
		headerTireImg() {
			if (this.errorLoading) {
				return '/content/drsg/b_iso/default.jpg';
			}

			const pictId = this.productSet.products[0].imageId;

			if (this.isMobile) {
				return `/content/drsg/b_xl_iso/${pictId}_b_xl.jpg`;
			}

			return `/content/drsg/c_xl_front/${pictId}_c_xl.jpg`;
		},
		headerTireImgAltText() {
			return `${ `${ this.$t('message.imagesOf') } ${this.productSet.products[0].brand} ${this.productSet.products[0].catalogEntryDescription}` }`;
		},
		tireModelID() {
			return this.productSet.products[0].modelID;
		},
		tireQuantity() {
			let counts = this.defaultQty;

			if (!isEmpty(this.qtySelected)) {
				counts = this.qtySelected;
			}

			return counts.reduce((total, q) => total + q, 0);
		},
		// eslint-disable-next-line complexity
		tireMessage() {
			let setNum = '';
			let totalQty = this.tireQuantity;

			switch (totalQty) {
				case 3:
					setNum = 'message.setOfThree';
					break;
				case 2:
					setNum = 'message.setOfTwo';
					break;
				case 1:
					setNum = 'message.setOfOne';
					break;
				default:
					setNum = 'message.setOfFour';
					break;
			}

			return setNum;
		},
		activeAppliedPromos() {
			if (this.productSet.promotions) {
				return this.productSet.promotions.filter(function (p) {
					return p.applied;
				});
			}

			return null;
		},
		hasAppliedPromo() {
			return this.activeAppliedPromos;
		},
		isMobileInstallAvailable() {
			// eslint-disable-next-line no-undefined
			return this.selectedDealer.transports.find(transport => transport.id === 14) !== undefined;
		},
		isAvailableFacingWarehouse() {
			return this.productSet.products.every((product) => product.isAvailableFacingWarehouse === true);
		},
		tireBrand() {
			return this.productSet.products[0].brand;
		},
		tireModel() {
			return this.productSet.products[0].catalogEntryDescription;
		},
		tireBrandAndModel() {
			return `${ this.tireBrand } ${ this.tireModel }`;
		},
		productDetailsTitle() {
			return `${ this.$t('message.goTo') }  ${ this.tireBrandAndModel } ${ this.$t('message.productDetailsPage') }`;
		},
		removeFromCompare() {
			return `${ this.$t('message.remove') } ${ this.tireBrandAndModel } ${ this.$t('message.fromCompare') }`;
		}
	},

	watch: {
		productSet() {
			this.errorLoading = false;
		},
		qtySelected() {
			this.refreshCompareProducts(structuredProductQuery(this.$route.query));
		}
	},

	methods: {
		...mapActions('products', ['removeProductSetFromCompare', 'setSingleProduct', 'refreshCompareProducts']),
		goToDetail(productSet) {
			const query = this.$route.query;

			productSkuEvent('comparePageProductNameClick', productSet);

			this.setSingleProduct({
				query: structuredProductQuery(query),
				id: productSet.products[0].productId,
				cid: query.cid || null,
				dealer: this.selectedDealer
			}).then(() => {
				this.$router.push({
					path: '/app/details',
					query: extend(query, {
						id: productSet.products[0].productId,
						localBest: productSet.localBestSeller
					})
				});
			});
		},
		toggleTireImageModal() {
			this.showTireImageModal = !this.showTireImageModal;
		},
		textForAccessibility(productSet) {
			let stringArray = '';
			const keysAndTitles = accessibilitySpecKeysAndTitles();

			for (const key in keysAndTitles) {
				const firstProduct = productSet.products[0];
				const value = firstProduct[key];
				const title = keysAndTitles[key];

				stringArray += `${title}, ${value}. `;
			}

			return stringArray;
		}
	}
};
</script>
